import Box from "@mui/material/Box";
import axios from 'axios';
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Navbar from '../component/Navbar';

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 8px;
`;

const Input = styled.input`
  padding: 8px;
  margin-bottom: 16px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const CheckboxLabel = styled.label`
  margin-left: 8px;
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ResponseMessage = styled.div`
  margin-top: 16px;
  color: ${(props) => (props.success ? 'green' : 'red')};
`;
const Dropdown = styled.select`
  padding: 8px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

function Assigntest() {
  const navigate = useNavigate();
  const [labCode, setLabCode] = useState('lab05');
  const [testId, setTestId] = useState('');
  const [testName, setTestName] = useState('');
  const [testPrice, setTestPrice] = useState('');

  const [isPackage, setIsPackage] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [isTest, setIsTest] = useState(false);
  
  const [sampleType, setsampleType] = useState('');
  const [container, setContainer] = useState('');
  const [sampleid, setSampleid] = useState('');
 const [clientdata, setclientdata] = useState([]);
 const [clientdatas, setclientdatas] = useState([]);
  const [sampledatas, setsampledatas] = useState([]);
const [sampledata, setsampledata] = useState([]);
  const [response, setResponse] = useState({ success: null, msg: '' });
  const [isSpecific, setIsSpecific] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange1 = () => {
    // Toggle the value between true and false
    setIsSpecific(prevState => !prevState);
  };
  const handleCheckboxChange = (checkboxName) => {
    setIsPackage(false);
    setIsProfile(false);
    setIsTest(false);
                                                             
    if (checkboxName === 'isPackage') setIsPackage(true);
    if (checkboxName === 'isProfile') setIsProfile(true);
    if (checkboxName === 'isTest') setIsTest(true);
  };


  useEffect(() => {
    // Fetch sample data when the component mounts
    axios.get('https://apiv2.labridge.in/admin/sampledata')
      .then((response) => setsampledatas(response.data))
      .catch((error) => console.error('Error fetching sample data:', error));
  }, []);
  useEffect(() => {
    // Fetch sample data when the component mounts
    let labids;
if(labCode == 'lab05'){
labids = '648ffe442be36ad5f1acb4ec';
}else if(labCode == 'lab06'){
  labids = '65266c8a27e4b9d512e0d54d';
}else if(labCode == 'lab08'){
  labids = '6585432d4f8639a1d3a6bd02';}
else{
labids = '65c1157532f8a73e726702d1';
}

    axios.get(`http://localhost:5012/admin/labwiseclient/${labids}`)
      .then((response) => setclientdata(response.data.data))
      .catch((error) => console.error('Error fetching sample data:', error));
  }, [isSpecific]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const apiResponse = await axios.post('https://apiv2.labridge.in/admin/addnewtest', {
        labCode,
        testId,
        testName,
        testPrice,
        container,
        isPackage: isPackage ? 1 : 0,
        isProfile: isProfile ? 1 : 0,
        isTest: isTest ? 1 : 0,
        isSpecific,
        sampleType,
        sampleid,
      });

      if(apiResponse.data.success){
        alert("Test Added");
        resetState();
      }
      setResponse(apiResponse.data);
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  const OnSelectTest = async (selectedList, selectedItem) => {
    const spamlearray = [];
    const containerarray = [];
    const sampleidarray = [];
    setsampleType("");
    setContainer("");
    setSampleid("");
  await (selectedList.map((element) => {
        spamlearray.push(element.sampleType);
        containerarray.push(element.container);
        sampleidarray.push(element.sampleid);
    }));
    console.log("selectedItem", selectedItem);
    setsampleType(spamlearray.toString());
    setContainer(containerarray.toString());
    setSampleid(sampleidarray.toString());
    setsampledata(selectedList);
  };
  const resetState = () => {
    setLabCode('lab05');
    setTestId('');
    setTestName('');
    setTestPrice('');
    setIsPackage(false);
    setIsProfile(false);
    setIsTest(false);
    setIsSpecific(false);
    setsampleType('');
    setContainer('');
    setSampleid('');
    setsampledata([]);

  };
  const OnRemoveTest = async (selectedList, removedItem) => {
    const spamlearray = [];
    const containerarray = [];
    const sampleidarray = [];
    setsampleType("");
    setContainer("");
    setSampleid("");
  await (selectedList.map((element) => {
        spamlearray.push(element.sampleType);
        containerarray.push(element.container);
        sampleidarray.push(element.sampleid);
    }));

    setsampleType(spamlearray.toString());
    setContainer(containerarray.toString());
    setSampleid(sampleidarray.toString());
    setsampledata(selectedList);
  };
  useEffect(() => {
    const isLoggedIn = localStorage.getItem('loginData');
    
  
    // If not logged in, redirect to the login page
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, []);
  return (
    <div className="wrapper">
      <Navbar />
      <div className="container mt-5" id='content'>
        <Container>
          <Title>Add New Test</Title>
          <Form onSubmit={handleSubmit}>
         
          <Label htmlFor="lab">Lab</Label>
          <Dropdown
            id="lab"
            value={labCode}
            onChange={(e) => setLabCode(e.target.value)}
          >
            <option value="lab05">BRL</option>
            <option value="lab06">zipatho</option>
            <option value="lab08">Nalasopara</option>
            <option value="lab09">Akola</option>
            <option value="lab10">Pune</option>
          </Dropdown>
       

            <Label>Test ID:</Label>
            <Input
              type="text"
              value={testId}
              onChange={(e) => setTestId(e.target.value)}
            />

            <Label>Test Name:</Label>
            <Input
              type="text"
              value={testName}
              onChange={(e) => setTestName(e.target.value)}
            />

            <Label>Test Price:</Label>
            <Input
              type="text"
              value={testPrice}
              onChange={(e) => setTestPrice(e.target.value)}
            />

            <CheckboxContainer>
              <input
                type="checkbox"
                checked={isPackage}
                onChange={() => handleCheckboxChange('isPackage')}
              />
              <CheckboxLabel>Package</CheckboxLabel>
            </CheckboxContainer>

            <CheckboxContainer>
              <input
                type="checkbox"
                checked={isProfile}
                onChange={() => handleCheckboxChange('isProfile')}
              />
              <CheckboxLabel>Profile</CheckboxLabel>
            </CheckboxContainer>

            <CheckboxContainer>
              <input
                type="checkbox"
                checked={isTest}
                onChange={() => handleCheckboxChange('isTest')}
              />
              <CheckboxLabel>Test</CheckboxLabel>
            </CheckboxContainer>
            <Box
              className="booktestlab-div1 mb-2"
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "35ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <Multiselect
                  options={sampledatas}
                  displayValue="sampleType"
                  onSelect={OnSelectTest}
                  onRemove={OnRemoveTest}
                  closeIcon="cancel"
                  selectedValues={sampledata}
                  placeholder="Select Sample"
                />
              </div>
            </Box>
            <label>
        <input
          type="checkbox"
          checked={isSpecific}
          onChange={handleCheckboxChange1}
        />
        This test is client specific
      </label>

      {isSpecific ?   <Box
              className="booktestlab-div1 mb-2"
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "35ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <Multiselect
                  options={clientdata}
                  displayValue="labName"
                  onSelect={OnSelectTest}
                  onRemove={OnRemoveTest}
                  closeIcon="cancel"
                  selectedValues={sampledata}
                  placeholder="Select Client"
                />
              </div>
            </Box> : <></>}
            <Button type="submit">Submit</Button>
          </Form>

          {response.success !== null && (
            <ResponseMessage success={response.success}>
              {response.success ? (
                <p>Test added successfully!</p>
              ) : (
                <p>Error: {response.msg}</p>
              )}
            </ResponseMessage>
          )}
        </Container>
      </div>
    </div>
  );
}

export default Assigntest;
